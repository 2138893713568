<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <iframe :src="block.iframe.languageProxy.src" :height="block.iframe.height" :style="iframeStyle"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-iframe #hot-reload-debug
export default {
    name: `cms-block-iframe`,
    components: {CmsBlock},
    extends: CmsBlock,
    computed: {
        iframeStyle() {
            let style = {
                borderRadius: this.block.iframe.borderRadius
            }
            if (this.block.iframe.height) {
                style.height = `${this.block.iframe.height}px`;
            }
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
iframe {
    border: none;
    width: 100%;
}
</style>
